
import { reactive, defineComponent, toRefs, watch } from 'vue';

const defaultFormThead = ['apple', 'banana'];
export default defineComponent({

  setup() {
    const dataMap = reactive({
      tableData: [
        {
          name: 'fruit-1',
          apple: 'apple-10',
          banana: 'banana-10',
          orange: 'orange-10'
        },
        {
          name: 'fruit-2',
          apple: 'apple-20',
          banana: 'banana-20',
          orange: 'orange-20'
        }
      ],
      key: 1, // Table key
      formTheadOptions: ['apple', 'banana', 'orange'],
      checkboxVal: defaultFormThead,
      formThead: defaultFormThead // Default header
    });

    watch(() => dataMap.checkboxVal, (value: string[]) => {
      console.log('----------------------');
      dataMap.formThead = dataMap.formTheadOptions.filter(i => value.indexOf(i) >= 0);
      dataMap.key = dataMap.key + 1; // Ensure the table will be re-rendered each time
    });

    return { ...toRefs(dataMap) };
  }

});
