
import { reactive, defineComponent, toRefs } from 'vue';

export default defineComponent({
  setup() {
    const dataMap = reactive({
      tableData: [
        {
          name: 'fruit-1',
          apple: 'apple-10',
          banana: 'banana-10',
          orange: 'orange-10'
        },
        {
          name: 'fruit-2',
          apple: 'apple-20',
          banana: 'banana-20',
          orange: 'orange-20'
        }
      ],
      formThead: ['apple', 'banana']
    });

    return { ...toRefs(dataMap) };
  }
});

