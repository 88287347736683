import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-container" }
const _hoisted_2 = { style: {"margin":"0 0 5px 20px"} }
const _hoisted_3 = { style: {"margin":"30px 0 5px 20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FixedHeaderTable = _resolveComponent("FixedHeaderTable")!
  const _component_UnfixedHeaderTable = _resolveComponent("UnfixedHeaderTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t('table.dynamicTips1')), 1),
    _createVNode(_component_FixedHeaderTable),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t('table.dynamicTips2')), 1),
    _createVNode(_component_UnfixedHeaderTable)
  ]))
}