
// import { Component, Vue } from 'vue-property-decorator'
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import FixedHeaderTable from './components/FixedHeaderTable.vue';
import UnfixedHeaderTable from './components/UnfixedHeaderTable.vue';
export default defineComponent({
  components: {
    FixedHeaderTable,
    UnfixedHeaderTable
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    };
  }
});

